@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  background: #ffa900;
  background-image: url(/images/top.png), url(/images/bottom.png);
  background-repeat: repeat-y, repeat;
}

.main {
  width: 600px;
  height: 600px;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 20x;
}